import { useEffect, useState } from 'react';
import { useAlertGetAllAlerts } from '../../../api/alert';
import AlertListFilter, {
  defaultAlertListFilters,
  IFilters,
} from '../../../components/AlertList/AlertListFilter';
import { useRouter } from '../../../modules/router/RouterProvider';
import { RiskBadge } from '../../../ui';
import AlertStatusBadge from '../../../ui/components/Badges/AlertBadge/AlertStatusBadge';
import Table from '../../../ui/components/Table/Table';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import AlertListAppliedFilter from '../../../components/AlertList/AlertListAppliedFilter';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import CurrencyBadge from '../../../components/ui/components/Badge/CurrencyBadge';
import { startCase } from 'lodash';
import Tab from '../../../components/ui/components/Tab/Tab';
import { ellipsis } from '../../../utils/helpers/helperFunctions';
import Popover from '../../../ui/components/Popover/Popover';
import { Tooltip } from '../../../ui/components/Tooltip';
import CustomerAddressList from '../../../components/Address/AddressList/CustomerAddressList';
import { Date } from '../../../components/ui/components/Date';
import { useAuth } from '../../../modules/auth';
import { Search } from '../../../components/ui/components/Search';
import { useLocalStorage } from 'react-use';
import { useWorkspace } from '../../../utils/helpers/common';
import { isEqual } from 'lodash';

const AlertList = () => {
  const screenApi = useScreenApi();
  const { navigate, getQueryParams } = useRouter();
  // const { tab  } = getQueryParams();
  const { level, start_time, end_time, rule_name, policy_type, policy_category, tab } = getQueryParams();
  const workspace = useWorkspace();

  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.alertFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = useState<IFilters>({
    level: level ? level.split(',').map(Number) : [],
    rule_name: rule_name ? rule_name.split(',') : [],
    start_time: start_time && start_time !== 'null' ? start_time : null,
    end_time: end_time && end_time !== 'null' ? end_time : null,
    policy_category: policy_category && policy_category !== 'null' ? policy_category : null,
    policy_type: policy_type && policy_type !== 'null' ? Number(policy_type) : null,
  });
  const selectedTab = tab ? parseInt(tab) : -1;
  const [search, setSearch] = useState('');
  const [currentTab, setTab] = useState(selectedTab);
  const [disableAllTabs, setDisableAllTabs] = useState(selectedTab < 0);
  const { state } = useAuth();
  const [isQueryEnabled, setIsQueryEnabled] = useState({
    open: false,
    escalated: false,
    resolved: false,
    stale: false,
    all: false,
  });
  const openAlertsQuery = useAlertGetAllAlerts(
    { ...filters, status: 0, is_stale: false, q: search },
    { enabled: isQueryEnabled.open || currentTab === 0 || currentTab === -1 }
  );
  const escalatedAlertsQuery = useAlertGetAllAlerts(
    { ...filters, status: 2, is_stale: false, q: search },
    {
      enabled: isQueryEnabled.escalated || currentTab === 1,
    }
  );
  const resolvedAlertsQuery = useAlertGetAllAlerts(
    { ...filters, status: 1, is_stale: false, q: search },
    { enabled: isQueryEnabled.resolved || currentTab === 2 }
  );
  const staleAlertsQuery = useAlertGetAllAlerts(
    { ...filters, is_stale: true, q: search },
    {
      enabled: isQueryEnabled.stale || currentTab === 3,
    }
  );
  const allAlertsQuery = useAlertGetAllAlerts(
    { ...filters, q: search },
    {
      enabled: isQueryEnabled.all || currentTab === 4,
    }
  );

  useEffect(() => {
    if (openAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, escalated: true }));
    }
  }, [openAlertsQuery.isFetched]);

  useEffect(() => {
    if (escalatedAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, resolved: true }));
    }
  }, [escalatedAlertsQuery.isFetched]);

  useEffect(() => {
    if (resolvedAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, stale: true }));
    }
  }, [resolvedAlertsQuery.isFetched]);

  useEffect(() => {
    if (staleAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, all: true }));
    }
  }, [staleAlertsQuery.isFetched]);

  useEffect(() => {
    if (allAlertsQuery.isFetched) {
      setIsQueryEnabled((prev) => ({ ...prev, open: true }));
    }
  }, [allAlertsQuery.isFetched]);

  const [allAlertsCount, allAlertsData] = flattenInfiniteQueryResult(allAlertsQuery?.data);
  const [openAlertsCount, openAlertsData] = flattenInfiniteQueryResult(openAlertsQuery?.data);
  const [resolvedAlertsCount, resolvedAlertsData] = flattenInfiniteQueryResult(resolvedAlertsQuery?.data);
  const [escalatedAlertsCount, escalatedAlertsData] = flattenInfiniteQueryResult(escalatedAlertsQuery?.data);
  const [staleAlertsCount, staleAlertsData] = flattenInfiniteQueryResult(staleAlertsQuery?.data);

  const alertsQuery =
    currentTab < 0
      ? openAlertsQuery
      : [openAlertsQuery, escalatedAlertsQuery, resolvedAlertsQuery, staleAlertsQuery, allAlertsQuery][
          currentTab
        ];
  const alertsCount =
    currentTab < 0
      ? openAlertsCount
      : [openAlertsCount, escalatedAlertsCount, resolvedAlertsCount, staleAlertsCount, allAlertsCount][
          currentTab
        ];
  const alertsData =
    currentTab < 0
      ? openAlertsData
      : [openAlertsData, escalatedAlertsData, resolvedAlertsData, staleAlertsData, allAlertsData][currentTab];

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach((key) => {
      if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const syncFilters = (newFilters: IFilters, tab_id?: number) => {
    if (state?.userProfile.email) {
      if (tab_id !== -1 || tab_id == null) {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set(
          'tab',
          tab_id?.toString() || tab_id === 0
            ? tab_id?.toString()
            : currentTab?.toString()
              ? currentTab?.toString()
              : '0'
        );
        Object.keys(newFilters).forEach((key) => {
          if (newFilters[key] && Array.isArray(newFilters[key]) && newFilters[key].length !== 0) {
            newSearchParams.set(key, newFilters[key].toString());
          }
        });
        setFilterStorage(newFilters);
        navigate(`/alerts`, Object.fromEntries(newSearchParams));
        setDisableAllTabs(false);
      }
    }
  };

  useEffect(() => {
    if (
      selectedTab < 0 &&
      openAlertsCount !== 0 &&
      !openAlertsQuery.isLoading &&
      openAlertsCount !== undefined
    ) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultAlertListFilters;
      setFilters(localStorageFilters);
      onChangeTab(0, localStorageFilters);
    } else if (openAlertsCount === 0 && currentTab === -1 && !openAlertsQuery.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultAlertListFilters;
      setFilters(localStorageFilters);
      onChangeTab(4, localStorageFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAlertsQuery.isLoading]);

  useEffect(() => {
    setTab(-1);
  }, [workspace.slug]);

  useEffect(() => {
    const localStorageFilters = filterStorage
      ? formatLocalStorageValue(filterStorage)
      : defaultAlertListFilters;

    workspace.workspaces.forEach((w) => {
      if (!localStorage.getItem(`compass.storage.alertFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.alertFilters.${w.slug}`,
          JSON.stringify(defaultAlertListFilters)
        );
      }
    });
    if (isEqual(filters, defaultAlertListFilters)) {
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters, currentTab);
    } else {
      setFilterStorage(filters);
      setDisableAllTabs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      if (filterStorage) {
        setFilters(filterStorage);
        Object.keys(filterStorage).forEach((key) => {
          // Not adding keys which are null and is an empty array to the url params
          if (
            filterStorage[key] !== null &&
            (!Array.isArray(filterStorage[key]) || filterStorage[key].length !== 0)
          ) {
            newSearchParams.set(key, filterStorage[key].toString());
          }
        });
      }

      // Add delay to wait for the tab to be set
      setTimeout(() => {
        const { tab } = getQueryParams();
        if (tab) newSearchParams.set('tab', tab);
        navigate(`/alerts`, Object.fromEntries(newSearchParams));
      }, 100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStorage]);

  const headerData = [
    'Alert ID',
    <Popover
      key={0}
      isBasic
      referenceContent='Identifier'
      popoverContent='Identifier of Address/Transaction or Customer id'
    />,
    'Blockchain',
    'Customer ID',
    'Policy Type & Category',
    'Rule Name',
    'Created at',
    'Risk level',
    'Status',
  ];
  const rows = alertsData?.map((alert) => {
    const entity = alert.content_object_fields;
    return {
      id: alert.id,
      data: [
        alert.id,
        <IdentifierEllipsis
          copyable
          key={alert.id}
          clickable
          identifier={entity.identifier || entity.customer_id}
        />,
        <CurrencyBadge key={alert.id} currency={entity.currency} />,
        alert.entity_type !== 'customer' ? <CustomerAddressList key={alert.id} address={entity} /> : null,
        startCase(alert.entity_type) + ' > ' + alert.rule_register.policy_category_verbose,
        <Popover
          key={alert.id}
          isBasic
          referenceContent={ellipsis(alert.rule_register.rule_name || '', 25)}
          popoverContent={alert.rule_register.rule_name}
        />,
        <Date key={alert.id} date={alert.created_at} type='duration' tz={state.userProfile.timezone} />,
        <RiskBadge key={alert.id} risk={alert.level} grayScale={alert.is_stale || alert.status === 1} />,
        <AlertStatusBadge key={alert.id} type={!alert.is_stale ? alert.status : 3} hideStatusLabel />,
      ],
      onClick: async () => {
        if (alert.entity_type === 'customer') {
          navigate(`/customers/${entity.customer_id}`, { alert: alert.id.toString() });
          return;
        }
        const entityLong = alert.entity_type === 'address' ? 'addresses' : 'transactions';
        const result = await screenApi(
          {
            identifier: entity.identifier,
            currency: entity.currency,
            entityType: entityLong,
            customer_id: entity.customer_id,
            type: entity.type,
            address: entity.deposit_address,
          },
          false
        );
        navigate(`/${entityLong}/${result.id}`, { alert: alert.id.toString() });
      },
    };
  });

  const tabs = [
    {
      label: 'Open',
      count: openAlertsCount,
    },
    {
      label: 'Escalated',
      count: escalatedAlertsCount,
    },
    {
      label: 'Resolved',
      count: resolvedAlertsCount,
    },
    {
      label: (
        <div>
          <Tooltip
            place='bottom'
            className='-ml-1 mr-1 bg-slate-300/100 leading-3 text-gray-900'
            content='Inactive alerts are alerts created in the past but are no longer active due to reasons indicated in the audit trail for the respective alert'
            id='inactive-alert-tooltip'
            label='i'
          />
          Inactive
        </div>
      ),
      count: staleAlertsCount,
    },
    {
      label: 'All',
      count: allAlertsCount,
    },
  ];

  const disableAllQueries = () => {
    setIsQueryEnabled({
      open: false,
      escalated: false,
      resolved: false,
      stale: false,
      all: false,
    });
  };

  const onApply = (filters) => {
    setFilters(filters);
    syncFilters(filters);
    disableAllQueries();
  };

  const onReset = () => {
    setFilters(defaultAlertListFilters);
    syncFilters(defaultAlertListFilters);
    disableAllQueries();
  };

  const onChangeTab = (index: number, newFilters?: IFilters) => {
    setTab(index);
    navigate('/alerts', { tab: index.toString() });
    if (newFilters) {
      syncFilters(newFilters, index);
    } else {
      syncFilters(filters, index);
    }
  };

  return (
    <div>
      <Table
        title='Alerts'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${alertsCount ?? 0} results`}
        heightOffset={26.5}
        tab={tab}
        headerActionsLeft={
          <Tab
            values={tabs}
            onChange={onChangeTab}
            type={'primary'}
            defaultIndex={currentTab}
            showCountLoading
            disableAllTabs={disableAllTabs}
            changeTab={currentTab ?? null}
          />
        }
        headerActions={
          <Search
            minChars={3}
            value={search}
            setValue={(q) => {
              setSearch(q);
              disableAllQueries();
            }}
            type='button'
          />
        }
        filterComponent={<AlertListFilter filters={filters} onApply={onApply} onReset={onReset} />}
        appliedFilters={<AlertListAppliedFilter filters={filters} setFilters={onApply} />}
        {...buildInfiniteQueryTableProps(alertsQuery)}
        isLoading={!alertsQuery.isFetched && !alertsQuery.isFetchingNextPage}
      />
    </div>
  );
};

export default AlertList;
