import * as React from 'react';
import AssociatedCases from '../../../components/AddressTransaction/AssociatedCases';
import CompassLoader from '../../../components/ui/components/Loader/CompassLoader';
import DetailPage from '../../../layouts/DetailPage';

import Tab, { ITabValue } from '../../../components/ui/components/Tab/Tab';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useCustomerGetCustomer } from '../../../api/customer';
import CustomerMetadata from '../../../components/Customer/CustomerMetadata/CustomerMetadata';
import CustomerOverview from '../../../components/Customer/Overview/CustomerOverview';
import CustomerTransactions from '../../../components/Customer/CustomerTransactions/CustomerTransactions';
import AuditLogs from '../../../components/common/AuditLogs';
import ProofOfReserves from '../../../components/Customer/ProofOfReserves';
import { useAuth } from '../../../modules/auth';

interface CustomerDetailsProps {
  id?: string;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = () => {
  const { getParams } = useRouter();
  const { state } = useAuth();
  const customerId = encodeURIComponent(getParams().id || getParams().customerId);

  const { data: response, isLoading: detailLoading } = useCustomerGetCustomer(customerId, {
    useErrorBoundary: true,
  });

  const [alerts, setAlerts] = React.useState(0);

  if (detailLoading) {
    return <CompassLoader />;
  }
  const tabs: ITabValue[] = [
    { label: 'Overview' },
    { label: 'Transactions' },
    {
      label: 'Associated Cases',
      count: response?.data?.associated_cases?.length,
      hidden: !response?.data?.associated_cases?.length,
    },
    {
      label: 'Audit Logs',
    },
    { label: 'Proof of Reserves', hidden: !state.userProfile?.is_proof_of_reserve_enabled },
  ];

  return (
    <DetailPage>
      <CustomerMetadata customer={response.data} alerts={alerts} />
      <div className=''>
        <Tab
          values={tabs}
          type='secondary'
          listClassName='border-b border-gray-200 py-2 px-4 sticky z-30 top-0'
          panelClassName='p-4'>
          <CustomerOverview customer={response.data} setAlerts={setAlerts} customerId={customerId} />
          <CustomerTransactions customerId={customerId} customer={response.data} />
          <AssociatedCases associatedCases={response.data.associated_cases} />
          <AuditLogs type='customer' id={response.data.customer_id} />
          <ProofOfReserves customerId={response.data.customer_id} />
        </Tab>
      </div>
    </DetailPage>
  );
};

export default CustomerDetails;
