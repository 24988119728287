import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { CaretDown, Circle } from '@phosphor-icons/react';
import { useAuth } from '../../../modules/auth';
import { ITabMenuType, ITabType } from './types';
import CustomLink from '../../ui/components/CustomLink';
import { useRouter } from '../../../modules/router/RouterProvider';
import { calculateVh, useCurrentRole } from '../../../utils/helpers/common';

interface TabsProps {
  tabs: ITabMenuType[];
  type: string;
}

const SettingsTabs: FC<TabsProps> = ({ tabs, type }) => {
  const { state } = useAuth();
  const { pathname } = useRouter();
  const { isAdmin } = useCurrentRole();

  const tabsToShow = (): ITabMenuType[] => {
    const tabsList = [];
    for (const outerTab of tabs) {
      if (outerTab.isHiddenAgent && !isAdmin) {
        continue;
      }

      const filteredTabs = outerTab.tabs.filter((innerTab) => {
        if (innerTab.name === 'api-keys' && !state.userProfile?.can_access_api) {
          return false;
        }
        return innerTab.isHiddenAgent ? isAdmin : true;
      });

      if (filteredTabs.length > 0) {
        tabsList.push({
          type: outerTab.type,
          tabs: filteredTabs,
        });
      }
    }
    return tabsList;
  };

  const isSubTabsVisible = (tabs: ITabType[]) => {
    if (!tabs) return;
    return tabs.some((tab) => pathname.includes(tab.name));
  };

  return (
    <div className='flex h-full'>
      <div className='ml-[256px]'>
        <div
          className='fixed left-0 w-64 min-w-[256px] flex-none overflow-y-auto overflow-x-hidden border-r pt-2'
          style={{
            height: calculateVh(62),
          }}>
          {tabsToShow().map((tab, i) => (
            <>
              <div className='p-3 pl-4 text-xs font-medium uppercase text-gray-500'>{tab.type}</div>
              <div className='mx-2'>
                <ul className='flex flex-col'>
                  {tab.tabs.map((m, j) => (
                    <>
                      <li key={j}>
                        <CustomLink
                          to={`/settings/${type}/${m.tabs ? m.tabs[0].name : m.name}`}
                          className={({ isActive }) =>
                            classNames(
                              {
                                'bg-blue-100 text-blue-600': isActive && !m.tabs,
                                'text-gray-800 hover:bg-blue-50 hover:text-blue-600': !isActive,
                              },
                              'group mb-2 flex items-center justify-between rounded-md px-3 py-2 text-sm'
                            )
                          }>
                          {m.label}
                          {m.tabs && <CaretDown className='my-auto' size={16} />}
                        </CustomLink>
                      </li>
                      {isSubTabsVisible(m.tabs) && (
                        <ul className=''>
                          {m.tabs.map((s, j) => (
                            <li key={j}>
                              <CustomLink
                                to={`/settings/${type}/${s.name}`}
                                className={({ isActive }) =>
                                  classNames(
                                    {
                                      'bg-blue-100 text-blue-600': isActive,
                                      'text-gray-800 hover:bg-blue-50 hover:text-blue-600': !isActive,
                                    },
                                    'group mb-2 ml-3 flex items-center rounded-md px-3 py-2 text-sm'
                                  )
                                }>
                                <Circle weight='fill' size={6} className='mr-2' />
                                {s.label}
                              </CustomLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ))}
                </ul>
              </div>
              {i < tabsToShow().length - 1 && <hr className='m-2' />}
            </>
          ))}
        </div>
      </div>
      <div className='flex-1 overflow-auto p-4 px-6'>
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsTabs;
